.main{
    padding-top: 4%;
    color: white;
}

.mainHeading{ 
    padding: 3%;
    text-align: center;
    letter-spacing: 1.5px;
    font-weight: bold;
}

.topMargin{
    margin-top: 5%;
}

.mainText{
    padding-left: 7%;
    padding-right: 7%;
    text-align: center;
    letter-spacing: 1.4px;
    line-height: 1.4;
}

.phoImg{
    display:block;
    margin:auto;
}

.foodBack{ 
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50%; */
    height: 20%;
    animation: bottomUp 1s;
    /* transform: rotateX(90deg) */
}

@keyframes bottomUp{
    0%{
        /* opacity: 0; */
        transform: translateY(100%)
    }
    100%  {
        /* opacity: 1; */
        transform: translateY(0%)
    }
}

.contact{
    margin: 7%;
    text-align: center;
}

.toggleBar{
    border-top: 2px solid #6A3940;
}

.show{
    display: block;
}

.hidden{
    display: none;
}

/* .headingButtons{
    display: grid;
    grid-template-columns: 1fr 1fr;
} */

.main button, .contactLink{
    margin: 3%;
    padding: 5% 5%;
    background-color: #ececea;
    border: 0;
    /* box-shadow: #ececea  2px 2px 5px; */
    border-radius: 10px;;
    /* font-size: 2vh; */
    letter-spacing: 2px;
    font-weight: bold;
    color: #6A3940;
    letter-spacing: 2px;
}

.main button:hover, .contactLink:hover{
    background-color: #6A3940;
    color: #f5f5ff;
    border: 1px solid#ececea;
}
@media only screen and (min-width: 600px){
  /* .main button, .contactLink{
    padding: 2% 3%;
  } */
}

@media only screen and (min-width: 1000px){
   .main{
        margin-right: 15%;
        margin-left: 15%;
        display: flex;
   }

   .mainHeading, .mainText{
        text-align: left;
   }
}