@media only screen and (min-width: 600px){
    .menu{
        margin-left: 25%;
        margin-right: 25%;
    }
}
  
@media only screen and (min-width: 800px){
    .menu{
        margin-left: 15%;
        margin-right: 15%;
    }
}