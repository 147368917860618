.navDiv {
    list-style-type: none;
    padding: 3%;
    display: grid;
    grid-template-columns: 85% 15%;
}

.navLink{
  padding: 3%;
  margin: 2%;
  color: #fafaf3;
}

.wholeBar{
    background-color: white;
    position: sticky;
    top: 0;
}

.navItems{
    align-items: center;
    z-index: 1000;
    position: absolute;
    right: 0%;
    padding: 1%;
    text-align: center;
    width: 20%;
    list-style-type: none;
    background-color: white;
    font-weight: bold;
    font-size: 2rem;
    z-index: 1;
    /* border: 1px solid black; */
}

a{
    text-decoration: none;
}

ul li a{
    color: #6A3940;
    font-size: 2.3vh; 
    /* margin: 5%;
    padding: 3%; */
}

.navLink a:after{
  display: block;
  content: '';
  border-bottom: 3px solid #6A3940;
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}

.navLink a:hover:after{
    transform: scaleX(1);
}

.hide{
    /* animation: hide .3s .1s; */
    transform: translateX(100%)
}

.show{
    animation: show .3s;
    /* display: block; */
    -webkit-box-shadow: -5px 20px 15px 5px #FFF5F9;
    box-shadow: -5px 20px 25px 5px #FFF5F9;
}

@keyframes show {
    from {
        transform: translateX(100%)
    } to {
        transform: translateX(0%);
    }
}

@keyframes hide {
    from {
        transform: translateX(0%)
    } to {
        transform: translateX(100%);
    }
}

@media only screen and (min-width: 600px){
    ul li{
        margin-right: 20%;
    }

    .toggle{
        display: none;
    }

    .navDiv {
        display: block;
        list-style-type: none;
        padding: 1%;
        display: grid;
        margin-left: 25%;
        grid-template-columns: 1fr; 
    }

    .wholeBar{
        display: grid;
        grid-template-columns: auto auto;
    }

    .navItems{
        position: static;
        list-style-type: none;
        z-index: 5;
        /* display: grid;
        grid-template-columns:auto auto; */
        display: flex;
    }
}

@media only screen and (min-width: 800px){
    .navLink{
        margin: 5%;
    }
   
}