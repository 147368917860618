h1{
    text-align: center;
    font-size: 2rem;
    margin: 3%;
}

.menuText{
    padding-bottom: 7%;
    text-align: center;
}

.mealOptions{
    display: flex;
    padding-bottom: 5%;
    padding-left: 5%;
    /* margin: 0 auto; */
    text-align: center;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */

}

.glutenImg{
    width: 50%;
}

.callInOrder{
    text-align: center;
    margin-bottom: 2%;
}

.callInOrder button{ 
    margin: 3%;
    padding: 5% 5%;
    background-color: #ececea;
    /* box-shadow: #ececea  2px 2px 5px; */
    border-radius: 10px;;
    /* font-size: 2vh; */
    letter-spacing: 2px;
    font-weight: bold;
    border: 1px solid #ececea;
    letter-spacing: 2px;
    color: #6A3940;
}

.callInOrder button:hover{
    background-color: #6A3940;
    color: #f5f5ff;
    
}

@media only screen and (min-width: 600px){
    .menuText{
        padding-bottom: 3%;
        text-align: center;
    }

    .mealOptions{
        padding-bottom: 2%;
        padding-left: 2%;
        text-align: center;
    }

    .glutenImg{
        width: 25%;
    }
}