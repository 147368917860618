.img{
    width: 15%;
    margin-left: 3%;
}

@media only screen and (min-width: 600px){
    .img{
        width: 10%;
        margin-left: 0;
    }
}