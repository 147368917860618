footer{
    text-align: center;
}


footer div{
   padding: 3%;
}

a{
    text-decoration: none; 
    color: white;
}

@media only screen and (min-width: 600px){
    footer{
        display: flex;
        margin: 3% 15%;
        text-align: left;
    }
   
}