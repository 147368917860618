@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,b, u, i, center,dl, dt, dd, ol, ul, li,fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}


html{
   font-family: 'Montserrat', sans-serif;
   background-color: #6A3940;
   color: #f5f5ff;
}

h1{
	font-weight: bold;
	font-size: 1vh;
}

.grow {
	 transition: all .2s ease-in-out; 
}
.grow:hover { 
	-webkit-transform: scale(1.1); 
	        transform: scale(1.1);
}

.img{
    width: 15%;
    margin-left: 3%;
}

@media only screen and (min-width: 600px){
    .img{
        width: 10%;
        margin-left: 0;
    }
}
.menuItem{
    margin-bottom: 5%;
    display: grid;
    grid-template-columns: 7% 72% 25%;
    grid-gap: 2%;
    padding: 2% 3%;
}

.itemHead{
    font-size: 1.2rem;
    margin-bottom: 1%;
    font-weight: bold;
}

.itemHeader{
    display: grid;
    grid-template-columns: 75% 25%;
}

.itemBody{
    font-size: .9rem;
    margin-bottom: 2%;
    font-weight: lighter;
}


.itemPrice{
    font-size: 1rem;
    font-weight: bold;
}
.dietImg{
    width: 45%;
}

@media only screen and (min-width: 600px){
    /* .dietImg{
        width: 25%;
    } */
}
.choiceList{
    list-style-type: none;
   
}

.choiceItem{
    padding-top: 1%
}

td{
    padding: 2%;
}

@media only screen and (min-width: 1000px){
    .gridOnDesk{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 3%;
    }
 }
h1{
    text-align: center;
    font-size: 2rem;
    margin: 3%;
}

.menuText{
    padding-bottom: 7%;
    text-align: center;
}

.mealOptions{
    display: flex;
    padding-bottom: 5%;
    padding-left: 5%;
    /* margin: 0 auto; */
    text-align: center;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */

}

.glutenImg{
    width: 50%;
}

.callInOrder{
    text-align: center;
    margin-bottom: 2%;
}

.callInOrder button{ 
    margin: 3%;
    padding: 5% 5%;
    background-color: #ececea;
    /* box-shadow: #ececea  2px 2px 5px; */
    border-radius: 10px;;
    /* font-size: 2vh; */
    letter-spacing: 2px;
    font-weight: bold;
    border: 1px solid #ececea;
    letter-spacing: 2px;
    color: #6A3940;
}

.callInOrder button:hover{
    background-color: #6A3940;
    color: #f5f5ff;
    
}

@media only screen and (min-width: 600px){
    .menuText{
        padding-bottom: 3%;
        text-align: center;
    }

    .mealOptions{
        padding-bottom: 2%;
        padding-left: 2%;
        text-align: center;
    }

    .glutenImg{
        width: 25%;
    }
}
@media only screen and (min-width: 600px){
    .menu{
        margin-left: 25%;
        margin-right: 25%;
    }
}
  
@media only screen and (min-width: 800px){
    .menu{
        margin-left: 15%;
        margin-right: 15%;
    }
}
.main{
    padding-top: 4%;
    color: white;
}

.mainHeading{ 
    padding: 3%;
    text-align: center;
    letter-spacing: 1.5px;
    font-weight: bold;
}

.topMargin{
    margin-top: 5%;
}

.mainText{
    padding-left: 7%;
    padding-right: 7%;
    text-align: center;
    letter-spacing: 1.4px;
    line-height: 1.4;
}

.phoImg{
    display:block;
    margin:auto;
}

.foodBack{ 
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50%; */
    height: 20%;
    -webkit-animation: bottomUp 1s;
            animation: bottomUp 1s;
    /* transform: rotateX(90deg) */
}

@-webkit-keyframes bottomUp{
    0%{
        /* opacity: 0; */
        -webkit-transform: translateY(100%);
                transform: translateY(100%)
    }
    100%  {
        /* opacity: 1; */
        -webkit-transform: translateY(0%);
                transform: translateY(0%)
    }
}

@keyframes bottomUp{
    0%{
        /* opacity: 0; */
        -webkit-transform: translateY(100%);
                transform: translateY(100%)
    }
    100%  {
        /* opacity: 1; */
        -webkit-transform: translateY(0%);
                transform: translateY(0%)
    }
}

.contact{
    margin: 7%;
    text-align: center;
}

.toggleBar{
    border-top: 2px solid #6A3940;
}

.show{
    display: block;
}

.hidden{
    display: none;
}

/* .headingButtons{
    display: grid;
    grid-template-columns: 1fr 1fr;
} */

.main button, .contactLink{
    margin: 3%;
    padding: 5% 5%;
    background-color: #ececea;
    border: 0;
    /* box-shadow: #ececea  2px 2px 5px; */
    border-radius: 10px;;
    /* font-size: 2vh; */
    letter-spacing: 2px;
    font-weight: bold;
    color: #6A3940;
    letter-spacing: 2px;
}

.main button:hover, .contactLink:hover{
    background-color: #6A3940;
    color: #f5f5ff;
    border: 1px solid#ececea;
}
@media only screen and (min-width: 600px){
  /* .main button, .contactLink{
    padding: 2% 3%;
  } */
}

@media only screen and (min-width: 1000px){
   .main{
        margin-right: 15%;
        margin-left: 15%;
        display: flex;
   }

   .mainHeading, .mainText{
        text-align: left;
   }
}
.toggle{
    background-color: white;
    padding: 0;
    border: none;
    margin: 1%;
    width: 80%;
    color: #6A3940;
}

.turn1{
    -webkit-animation: topBar .5s;
            animation: topBar .5s;
    -webkit-transform: rotate(45deg) translateY(12px);
            transform: rotate(45deg) translateY(12px);
}

.turn2{
    -webkit-animation: midBar .3s;
            animation: midBar .3s;
    opacity: 0;
}

.turn3{
    -webkit-animation: bottomBar .3s;
            animation: bottomBar .3s;
    -webkit-transform: rotate(-45deg) translateY(-12px);
            transform: rotate(-45deg) translateY(-12px)
}

@-webkit-keyframes topBar {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } 
    100%{
        -webkit-transform: rotate(45deg) translateY(12px);
                transform: rotate(45deg) translateY(12px);
    }
}

@keyframes topBar {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } 
    100%{
        -webkit-transform: rotate(45deg) translateY(12px);
                transform: rotate(45deg) translateY(12px);
    }
}

@-webkit-keyframes midBar {
    0% {
        opacity: 100%;
    }
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        opacity: 0%;
    }
}

@keyframes midBar {
    0% {
        opacity: 100%;
    }
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        opacity: 0%;
    }
}

@-webkit-keyframes bottomBar {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } 
    100%{
        -webkit-transform: rotate(-45deg) translateY(-12px);
                transform: rotate(-45deg) translateY(-12px)
    }
}

@keyframes bottomBar {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } 
    100%{
        -webkit-transform: rotate(-45deg) translateY(-12px);
                transform: rotate(-45deg) translateY(-12px)
    }
}

.navDiv {
    list-style-type: none;
    padding: 3%;
    display: grid;
    grid-template-columns: 85% 15%;
}

.navLink{
  padding: 3%;
  margin: 2%;
  color: #fafaf3;
}

.wholeBar{
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.navItems{
    align-items: center;
    z-index: 1000;
    position: absolute;
    right: 0%;
    padding: 1%;
    text-align: center;
    width: 20%;
    list-style-type: none;
    background-color: white;
    font-weight: bold;
    font-size: 2rem;
    z-index: 1;
    /* border: 1px solid black; */
}

a{
    text-decoration: none;
}

ul li a{
    color: #6A3940;
    font-size: 2.3vh; 
    /* margin: 5%;
    padding: 3%; */
}

.navLink a:after{
  display: block;
  content: '';
  border-bottom: 3px solid #6A3940;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);  
  transition: -webkit-transform 250ms ease-in-out;  
  transition: transform 250ms ease-in-out;  
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
}

.navLink a:hover:after{
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.hide{
    /* animation: hide .3s .1s; */
    -webkit-transform: translateX(100%);
            transform: translateX(100%)
}

.show{
    -webkit-animation: show .3s;
            animation: show .3s;
    /* display: block; */
    box-shadow: -5px 20px 25px 5px #FFF5F9;
}

@-webkit-keyframes show {
    from {
        -webkit-transform: translateX(100%);
                transform: translateX(100%)
    } to {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

@keyframes show {
    from {
        -webkit-transform: translateX(100%);
                transform: translateX(100%)
    } to {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

@-webkit-keyframes hide {
    from {
        -webkit-transform: translateX(0%);
                transform: translateX(0%)
    } to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}

@keyframes hide {
    from {
        -webkit-transform: translateX(0%);
                transform: translateX(0%)
    } to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}

@media only screen and (min-width: 600px){
    ul li{
        margin-right: 20%;
    }

    .toggle{
        display: none;
    }

    .navDiv {
        display: block;
        list-style-type: none;
        padding: 1%;
        display: grid;
        margin-left: 25%;
        grid-template-columns: 1fr; 
    }

    .wholeBar{
        display: grid;
        grid-template-columns: auto auto;
    }

    .navItems{
        position: static;
        list-style-type: none;
        z-index: 5;
        /* display: grid;
        grid-template-columns:auto auto; */
        display: flex;
    }
}

@media only screen and (min-width: 800px){
    .navLink{
        margin: 5%;
    }
   
}
footer{
    text-align: center;
}


footer div{
   padding: 3%;
}

a{
    text-decoration: none; 
    color: white;
}

@media only screen and (min-width: 600px){
    footer{
        display: flex;
        margin: 3% 15%;
        text-align: left;
    }
   
}
