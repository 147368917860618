@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,b, u, i, center,dl, dt, dd, ol, ul, li,fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}


html{
   font-family: 'Montserrat', sans-serif;
   background-color: #6A3940;
   color: #f5f5ff;
}

h1{
	font-weight: bold;
	font-size: 1vh;
}

.grow {
	 transition: all .2s ease-in-out; 
}
.grow:hover { 
	transform: scale(1.1);
}
