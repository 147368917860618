.menuItem{
    margin-bottom: 5%;
    display: grid;
    grid-template-columns: 7% 72% 25%;
    grid-gap: 2%;
    padding: 2% 3%;
}

.itemHead{
    font-size: 1.2rem;
    margin-bottom: 1%;
    font-weight: bold;
}

.itemHeader{
    display: grid;
    grid-template-columns: 75% 25%;
}

.itemBody{
    font-size: .9rem;
    margin-bottom: 2%;
    font-weight: lighter;
}


.itemPrice{
    font-size: 1rem;
    font-weight: bold;
}