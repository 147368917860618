.choiceList{
    list-style-type: none;
   
}

.choiceItem{
    padding-top: 1%
}

td{
    padding: 2%;
}